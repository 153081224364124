<template>
  <div>
    <div class="card-info">
      <h3 class="headline border-bottom-0 pb-0">Select Wing</h3>
      <ul class="list-group custom-inline-box">
      <li v-for="typology in wingtypologies" :key="typology.key" class="list-group-item list-group-item-action">
       <div :id="`floor=${typology.uuid}`" class="custom-control custom-radio">
        <input
          type="radio"
          :id="'typology-' + typology.key"
          :value="typology"
          v-model="selectedTypologyLocal"
          @change="updateTypology"
          class="custom-control-input"
        />
        <label :for="'typology-' + typology.key" class="custom-control-label">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.06671 8.19992L4.63337 6.76659C4.51115 6.64436 4.3556 6.58325 4.16671 6.58325C3.97782 6.58325 3.82226 6.64436 3.70004 6.76659C3.57782 6.88881 3.51671 7.04436 3.51671 7.23325C3.51671 7.42214 3.57782 7.5777 3.70004 7.69992L5.60004 9.59992C5.73337 9.73325 5.88893 9.79992 6.06671 9.79992C6.24448 9.79992 6.40004 9.73325 6.53337 9.59992L10.3 5.83325C10.4223 5.71103 10.4834 5.55547 10.4834 5.36659C10.4834 5.1777 10.4223 5.02214 10.3 4.89992C10.1778 4.7777 10.0223 4.71659 9.83337 4.71659C9.64448 4.71659 9.48893 4.7777 9.36671 4.89992L6.06671 8.19992ZM7.00004 13.6666C6.07782 13.6666 5.21115 13.4916 4.40004 13.1416C3.58893 12.7916 2.88337 12.3166 2.28337 11.7166C1.68337 11.1166 1.20837 10.411 0.858374 9.59992C0.508374 8.78881 0.333374 7.92214 0.333374 6.99992C0.333374 6.0777 0.508374 5.21103 0.858374 4.39992C1.20837 3.58881 1.68337 2.88325 2.28337 2.28325C2.88337 1.68325 3.58893 1.20825 4.40004 0.858252C5.21115 0.508252 6.07782 0.333252 7.00004 0.333252C7.92226 0.333252 8.78893 0.508252 9.60004 0.858252C10.4112 1.20825 11.1167 1.68325 11.7167 2.28325C12.3167 2.88325 12.7917 3.58881 13.1417 4.39992C13.4917 5.21103 13.6667 6.0777 13.6667 6.99992C13.6667 7.92214 13.4917 8.78881 13.1417 9.59992C12.7917 10.411 12.3167 11.1166 11.7167 11.7166C11.1167 12.3166 10.4112 12.7916 9.60004 13.1416C8.78893 13.4916 7.92226 13.6666 7.00004 13.6666Z" fill="#0267C1"/>
          </svg>
          <div class="floor-section">
          <div class="floor-data">
           <span class="floor-value"> {{ typology.key }} </span>
          </div>
         </div>
        </label>
        </div>
      </li>
      <!-- <li class="form-check typology-item">
        <div class="d-flex">
          <div>
            <div class="form-group total-floors-section d-flex align-items-end">
              <img src="@/assets/images/total-floors.svg" width="44" height="44" class="" /> 
              <div>
                <label class="sub-head-label">Total Floors</label>
                <span class="sub-head">{{ selectedTypologyLocal.floor }}</span>
              </div>
            </div>
          </div>
        </div>
      </li> -->
      </ul>
      <div class="submit-button-section">
        <DownArrow v-if="isMobile" v-show="isDownVisible"/>
        <div class="submit-button-card">
          <span v-if="isMobile" class="submit-message">Select Wing and Continue</span>
          <router-link @click.native="navigate" :to="{ name: 'Floorplan', query: { ...$route.query } }" class="btn btn-block generate-eoi" :class="{'disabled' : !selectedTypology}" >Continue</router-link>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center carousel-container">
      <div class="gallery-title">Photo <span class="gallery-title-bld"> Gallery</span></div>
      <div class="typology-carousel-overlay"></div>
      <carousel ref="carousel" class="typology-carousel" :per-page="1" :autoplay="true" :pagination-enabled="false" :navigation-enabled="true" :loop="true" :mouse-drag="false" :navigation-prev-label="prevLabel" :navigation-next-label="nextLabel">
        <slide v-for="slide in slideImages" :key="slide.key">
          <a v-if="slide.image" :href="slide.image" data-fancybox="gallery">
            <img :src="slide.image" alt="" class="img-fluid m-0 carousel-img">
          </a>
        </slide>
      </carousel>
    </div>
    <button @click="$router.push({ name: 'Home', query: { ...$route.query } })" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="Back" height="16" width="16"> Back
    </button>
  </div>
</template>

<script>
import { masterJson } from '@/assets/scripts/utility.js';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
    DownArrow: () => import('@/components/Common/DownArrow.vue')
  },
  data() {
    return {
      prevLabel: '',
      nextLabel: '',
      isDownVisible: true,
      selectedTypologyLocal: {
        uuid: "",
        value: "Select Typology",
        floor: "-"
      }
    };
  },
  methods: {
    getDefaultTypology() {
      const selected = this.$store.getters.selectedTypology;
      return selected || {
        uuid: "",
        value: "Select Typology",
        floor: "-"
      };
    },
    updateTypology() {
      this.$store.dispatch('setTypology', this.selectedTypologyLocal);
    },
    navigate() {
      this.$store.dispatch('setLoading', true);
    },
    handleScroll(event) {
      this.isDownVisible = window.innerHeight + window.scrollY < document.body.offsetHeight;
    }
  },
  computed: {
    slideImages() {
      return masterJson.Buildings.filter(building => !!building.image);
    },
    typologies() {
      return masterJson.Buildings;
    },
    wingtypologies(){
      return masterJson.Buildings.filter(building => building.isWing);
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    isGifLoading() {
      return this.$store.getters.isGifLoading;
    },
    isMobile() {
      return window && window.innerWidth <= 991;
    }
  },
  watch: {
    '$store.getters.selectedTypology': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.selectedTypologyLocal = newValue;
          // document.getElementById(`floor=${newValue.uuid}`).scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    },
    isGifLoading(newValue) {
      if (!newValue) {
        this.$refs.carousel.$forceUpdate();
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.carousel.$forceUpdate();
      this.$refs.carousel.computeCarouselWidth();
    }, 300);
    this.selectedTypologyLocal = this.selectedTypology;
    if (this.isMobile) {
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    }
  },
  unmounted() {
    if (this.isMobile) {
      window.removeEventListener('scroll', this.handleScroll, { passive: true });
    }
  }
};
</script>

<style scoped>
.generate-eoi {
  background: #0075C4;
  border-radius: 4px;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  font-weight: 600;
  padding: 15px 15px 16px 15px;
  box-shadow: none;
}
.generate-eoi:hover {
  background: #0692f0;
  color: #FFFFFF;
}
.card-info {
background: #FFFFFF;
/* border: 1px solid rgba(73, 72, 153, 0.2); */
/* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03); */
/* border-radius: 10px; */
margin-top: 16px;
margin-left: 0;
margin-right: 0;
padding-bottom: 16px;
}
.side-menu.typology {
  min-height: auto!important;
}
.outer-wrapper {
  margin: 0;
  padding-top: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 11.68%, #10020F 25.64%);

}

.plan-holder {
  border-radius: 10px;
  height: 650px;
  padding: 0;
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu {
  min-height: 650px;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

select.form-control{
  background: url('../../assets/images/select-dropdown.svg');
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  background-position: right 14px top 18px;
  background-repeat: no-repeat;
  min-height: 50px;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;

}

.custom-control {
  margin-right: 0;
}

.custom-control-label::before, 
.custom-control-label::after{
  display: none !important;
}

.custom-control-label {
  padding: 6px 8px !important;
  width: 100px !important;
  border: 1px solid rgba(2, 103, 193, 0.2);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  transition: 0.3s;
  cursor: pointer;
}

.custom-control-label svg{
  margin-bottom: 2px;
  opacity: 0.5;
  transition: 0.3s;
}

.custom-radio .custom-control-input:checked~.custom-control-label{
  border: 1px solid rgba(0, 117, 196, 1);
  border-radius: 4px
}

.custom-radio .custom-control-input:checked~.custom-control-label .floor-value{
  color: rgba(0, 117, 196, 1);
}

.custom-radio .custom-control-input:checked~.custom-control-label svg{
  opacity: 1;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px !important;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 13px;
  box-shadow: none;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 13px;
  left: 15px;
  margin: 2px;
}

.custom-control-input:focus ~ .custom-control-label::before{
  box-shadow: none;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}

.prevButton:hover{
  gap: 10px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action .btn-primary:disabled{
  opacity: 1;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.form-wrapper {
  padding: 0;
}
.form-wrapper  label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}
.sub-head {
  font-weight: 600;
  font-size: 13px;
  color: #082D57;
  display: block;
  line-height: 16px;
}

.total-floors-section img{
  margin-right: 16px;
}

.form-wrapper .head-label{
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 19px;
  text-align: left;
  margin-bottom: 24px;
  color: #000000;
  opacity: 1;
}

.sub-head-label{
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
  color: #00000080;
}
.card-foot {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
  }
  .card-footer-head span{
    letter-spacing: -0.01em;
    color: #082D57;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
  }
  h5 {
    font-weight: 600;
    font-size: 15px;
    color: #082D57;
  }
.link-btn {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

.typology-carousel-overlay{
    background: linear-gradient(133.4deg, rgba(0, 0, 0, 0.5) 12.76%, rgba(0, 0, 0, 0) 32.4%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 10px;
    pointer-events: none;
}
.typology-carousel{
   border-radius: 10px;
}

.carousel-container{
  position: relative;
}

.gallery-title{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 16px;
  padding-left: 16px;
}

.carousel-img{
  width: 100%;
  height: 100%;
  max-height: 250px;
  object-fit: cover;
}
.gallery-title span{
  font-weight: 800;
}
.submit-message{
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 16px;
}

.typology-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.typology-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #E0E0E0; /* Light gray separator */
  background: #FFFFFF;
}

.list-group-item {
  padding: 0;
  border: none;
  width: fit-content;
}

.list-group{
  display: flex;
  height: 340px;
  flex-wrap: wrap;
  gap: 12px;
  width: fit-content;
  padding-top: 16px;
}


.list-group::-webkit-scrollbar {
	width: 8px;
}

.list-group::-webkit-scrollbar-track {
	background: transparent
}

.list-group::-webkit-scrollbar-thumb {
	background: #b0b0b0;
	border-radius: 10px;
	width: 8px;
}

.list-group::-webkit-scrollbar-thumb:hover {
	background: #5d5e5f;
}

.list-group-item{
  padding-left: 0px;
  padding-right: 0px;
}

.custom-control {
  margin-right: 0;
  cursor: pointer;
}


.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: 50% 50% no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2B2D42'/%3e%3c/svg%3e");
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before{
  background-color:#ffffff; 
  outline: none;
}

.custom-control-input:not(:disabled):not(:checked):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):not(:checked):focus ~ .custom-control-label::before{
  border-color:#000000;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color:#2B2D42;
  background-color:#ffffff;
}


.custom-radio .custom-control-input:checked ~ .custom-control-label .price-range-title{
  color: rgba(0, 0, 0, 0.5);
}

.custom-control-input:checked ~ .custom-control-label .divider{
  display: none;
}

custom-control-label::before,
.custom-control-label::after {
  top: 13px;
  box-shadow:none;
  left: 15px;
}

.floor-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.floor-value{
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}
h3{
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 0;
}

.submit-button-card{
  padding: 16px;
}


@media (max-width:991px){
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .list-group{
    height: 340px;
  }

  .floor-value{
   font-size: 14px;
  }

  .custom-control-label{
    width: 90px !important;
  }

  .card-info{
    border-radius: 0;
    margin-top: 0;
    padding: 16px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .typology-carousel{
    width: 100%;
    overflow-x: hidden;
    border-radius: 0;
  }
  .typology-carousel-overlay{
    border-radius: 0;
  }

  .headline{
    border: none;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  .submit-button-section{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1003;
    width: 100%;
  }
  .submit-button-card{
    padding: 16px;
    background: #ffffff;
    width: 100%;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    text-align: center;
  }
  .generate-eoi{
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1199px){
  .list-group{
    height: 480px;
  }
}

</style>